var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"today-working"},[_c('div',{staticClass:"today-working__menu"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.todayDate),expression:"todayDate"}],staticClass:"curr-date",attrs:{"type":"date"},domProps:{"value":(_vm.todayDate)},on:{"input":function($event){if($event.target.composing){ return; }_vm.todayDate=$event.target.value}}}),_c('xlsx-workbook',[_vm._l((_vm.sheets),function(sheet){return _c('xlsx-sheet',{key:sheet.name,attrs:{"collection":sheet.data,"sheet-name":sheet.name}})}),_c('xlsx-download',{attrs:{"filename":(_vm.today + ".xlsx")}},[_c('button',{staticClass:"download-btn"},[_vm._v("Скачать")])])],2)],1),_c('div',{staticClass:"today-working__grid"},[(_vm.todayWorks)?_c('div',{staticClass:"cars-grid"},_vm._l((_vm.todayWorks),function(car,ca){return _c('div',{key:ca,staticClass:"car"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card__title",style:({ background: car.crewDetails.length > 1 ? null : "red" }),attrs:{"title":("Открыть график экипажа машины " + (car.mark.toUpperCase()) + " " + (car.number))},on:{"click":function($event){$event.preventDefault();return _vm.openCarCrewPopup(car.carID)}}},[_vm._v(" "+_vm._s(car.mark.toUpperCase())+" "+_vm._s(car.number)+" ")]),_vm._l((car.crewDetails),function(driver,dr){return _c('div',{key:dr,staticClass:"card__driver",attrs:{"title":("Открыть график сотрудника " + (driver.name))},on:{"click":function($event){$event.preventDefault();return _vm.openShedulePopup(driver.driverID)}}},[_c('span',[_vm._v(_vm._s(driver.name))]),(driver.todayExtra)?_c('span',{style:({
                background: ("" + (driver.todayExtra.bgColor)),
                display: "inline-block",
                width: "25px",
                heigth: "25px",
                border: "1px solid black",
                position: "absolute",
                right: "20px",
              })},[_vm._v(_vm._s(driver.todayExtra.cut))]):_vm._e()])})],2)])}),0):_vm._e()]),_c('CarCrewPopUp'),_c('ShedulePopUp')],1)}
var staticRenderFns = []

export { render, staticRenderFns }